<template>
  <div class="scoped_cover_div">
    <div class="search-text">
      <input type="text" class="filter-text" @input="fetchUsers()" v-model="filter.text" placeholder="Filter Name or Email">
    </div>
    <div class="jobs_table sp2">
      <table>
        <tr>
          <th>Name</th>
          <th>Unit</th>
          <th>Email</th>
          <th>Action</th>
        </tr>
        <tbody v-if="!loading">
          <tr v-for="(user,i) in users" :key="i">
            <td>{{user.name}}</td>
            <td>{{user.branch.branch_id}}</td>
            <td>{{user.email}}</td>
            <td>
              <a @click="updatePassword(user)" class="table_action_btn" title="Change Password">CHANGE PASSWORD</a>
            </td>
          </tr>
      </tbody>
      </table>
    </div>

    <div class="black-overlay" v-if="popupdatePassword">
      <div class="box-modal smallPops">
        <div class="body">
          <popChangePassword @closeModal="popupdatePassword = '' " :user="popupdatePassword" v-if="popupdatePassword"/>
        </div>
      </div>
    </div>
    <loader v-if="loading" />
    <paginate v-if="pages > 0" v-show="!loading" v-model="current_page" :page-count="pages"
      active-class="paginate-active" :click-handler="fetchUsers" prev-link-class="paginate-previous"
      next-link-class="paginate-next" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'"
      :no-li-surround="true">
    </paginate>
  </div>
</template>
<script>
import { param } from "jquery";
import popChangePassword from "./popups/popChangePassword.vue";
export default {
  name: "PasswordManagement",
  components: { popChangePassword },
  data() {
    return {
      showpopChangePassword: false,
      users:[],
      popupdatePassword: "",
      pages: 0,  
      current_page: 1, 
      loading: false, 
      filter:{
        text:'',
      },
    };
  },
  created(){
    this.fetchUsers();
  },
  methods: {
    fetchUsers(page = 1) {
      this.loading = true;
      this.current_page = page;
      this.axios.get(`/api/user/all`, {
        params:{
          ...this.filter,
          page:page
        }
      })
    .then(response => {
          console.log(response)
      this.users = response.data.data.users;
          this.pages = response.data.data.total_page; 
          this.loading = false; 
    })
    .catch(error => {
      this.loading = false;
          console.log(error);
    });
    },
    updatePassword(user) {
      this.popupdatePassword = user;
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 10px;
  vertical-align: middle;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
  width: 33.33%;
}
.search-text {
  background-color: #004a7c;
  margin: 0px;
  width: 100%;
  padding: 15px;
  display: flex;
  .filter-text {
    margin-left: auto;
    padding: 5px;
    font-size: 1rem;
  }
}

.table_action_btn {
  width: 180px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #06A5ED;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
  width: auto;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #063657;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #063657;
  font-weight: normal;
}
.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}
</style>